import React from 'react'
import { Link } from 'gatsby'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { NavigateNext, NavigateBefore } from '@material-ui/icons'
import PropTypes from 'prop-types'

const styles = theme => ({
    theNav: {
        width: '100%',
        paddingBottom: theme.spacing.unit*4,
        color: theme.palette.secondary.main,
        '& span': {
            //marginBottom: theme.spacing.unit*2,
            color: theme.palette.secondary.main,
            lineHeight: 'normal'
        },
    },
    pageLinkLeft: {
        textDecoration: 'none',
        textTransform: 'uppercase',
        float: 'left',
        display: 'flex',
        alignItems: 'center'
    },
    pageLinkRight: {
        textDecoration: 'none',
        textTransform: 'uppercase',
        textAlign: 'right',
        float: 'right',
        display: 'flex',
        alignItems: 'center'
    },
    pageIcon: {
        paddingLeft: theme.spacing.unit*0.2,
        paddingRight: theme.spacing.unit*0.2,
        //paddingTop: theme.spacing.unit*0.6,
        //verticalAlign: 'text-bottom',
        color: theme.palette.secondary.main,
    }
})

const PostsNav = ({ context, classes }) => {
    const prev = context.prev
    const next = context.next

    const prevPageLink = prev == null ? null : `${prev.frontmatter.path}`
    const nextPageLink = next == null ? null : `${next.frontmatter.path}`

    return (
        <Typography className={classes.theNav}>
            {next!=null?<Link to={`${nextPageLink}`} className={classes.pageLinkLeft} title='Next post'><NavigateBefore className={classes.pageIcon} /><span>Next</span></Link>:''}
            {prev!=null?<Link to={`${prevPageLink}`} className={classes.pageLinkRight} title='Previous post'><span>Prev</span><NavigateNext className={classes.pageIcon} /></Link>:''}
        </Typography>
    )
}

PostsNav.propTypes = {
    context: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(PostsNav)