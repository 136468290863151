import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../Layout'
import { withStyles } from '@material-ui/core/styles'
import MinutesToRead from '../common/MinutesToRead'
import Typography from '@material-ui/core/Typography'
import Img from 'gatsby-image'
import PostTags from '../common/PostTags'
import PostCategories from '../common/PostCategories'
import PostsNav from '../common/PostsNav'
import SocialShare from '../common/SocialShare'
import SocialPortal from '../common/SocialPortal'
import siteConfig from '../../../config/siteConfig'
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'

const styles = theme => ({
    blogContainer: {
        //textAlign: 'center',
        padding: `${theme.spacing.unit * 4}px ${theme.spacing.unit * 4}px`,
        [theme.breakpoints.up('md')]: {
            padding: `${theme.spacing.unit * 4}px ${theme.spacing.unit * 8}px`
        }
    },
    blogPost: {},
    blogTitle: {
        textTransform: 'uppercase',
        fontSize: '3rem',
        lineHeight: 1,
        fontWeight: '500'
    },
    blogDateTime: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    blogDate: {
        //fontSize: '1rem',
        marginTop: theme.spacing.unit*3,
        //display: 'inline',
        //float: 'left',
        color: 'gray',
        fontStyle: 'italic',
    },
    blogTime: {
        marginTop: theme.spacing.unit*3,
        //display: 'inline',
        //float: 'right',
        color: 'gray',
        fontStyle: 'italic',
    },
    blogContent: {
        '& a': {
            textDecoration: 'none',
            //borderBottom: '2px dashed gray',
            color: '#40C4FF',
        },
        '& p': {
            fontSize: '1rem',
            [theme.breakpoints.up('sm')]:{
                fontSize: '1.2rem',
            },
            //textIndent: '1.5rem',
            margin: 0,
            paddingTop: theme.spacing.unit*2,
            paddingBottom: theme.spacing.unit*2,
        },
        '& ul': {
            fontSize: '1rem',
            [theme.breakpoints.up('sm')]:{
                fontSize: '1.2rem',
            },
            //textIndent: '1.5rem',
            margin: 0,
            paddingLeft: '20px',
        },
        '& img': {
            borderRadius: '0.3em',
            width: '100%'
        },
        '& blockquote': {
            marginInlineStart: '1rem',
            marginInlineEnd: '1rem',
        },
        '& blockquote > p': {
            textIndent: 0,
            paddingLeft: theme.spacing.unit*2,
            paddingRight: theme.spacing.unit*1,
            backgroundColor: 'lightgray',
            fontStyle: 'italic',
            boxShadow: '6px 0px 0px 0px inset #A20471',
            '-webkit-boxShadow': '6px 0px 0px 0px inset #A20471',
            '-moz-boxShadow': '6px 0px 0px 0px inset #A20471',
            '-ms-boxShadow': '6px 0px 0px 0px inset #A20471',
        },
        '& div.file-name': {
            backgroundColor: 'lightgray',
            fontStyle: 'italic',
            boxShadow: '0px 3px 0px 0px inset #A20471',
            '-webkit-boxShadow': '0px 3px 0px 0px inset #A20471',
            '-moz-boxShadow': '0px 3px 0px 0px inset #A20471',
            '-ms-boxShadow': '0px 3px 0px 0px inset #A20471',
            padding: theme.spacing.unit*0.5,
            color: 'gray',
        },
        '& div.file-name + div': {
            margin: '0 0 0.5em 0',
            borderRadius: '0 0 0.3em 0.3em'
        },
        '& .gatsby-resp-image-figcaption': {
            textAlign: 'center',
            fontStyle: 'italic',
            fontSize: '0.8rem',
            color: 'gray'
        },
        '& hr': {
            opacity: '0.4'
        },
        '& h2': {
            fontSize: '1.6rem',
            margin: 0,
            paddingTop: theme.spacing.unit*4,
            paddingBottom: theme.spacing.unit
        },
        '& .gatsby-highlight pre':{
            [theme.breakpoints.down('xs')]:{
                '-webkit-text-size-adjust': '100%',
            }
        },
        paddingTop: '2rem',
        paddingBottom: theme.spacing.unit * 2,
    },
    postThumbnail: {
        width: '100%',
        //marginTop: '2rem'
    },
    postCategories: {
        textAlign: 'center'
    },
    postFooter: {
        padding: `${theme.spacing.unit*2}px 0px`,
        fontSize: '1rem',
        textAlign: 'center'
    },
    disqusForm: {
        paddingTop: theme.spacing.unit*8,
    },
    postHr: {
        opacity: '0.4'
    }
})

class Template extends Component {
    constructor(props) {
        super(props)

        this.postURL = this.props.data.markdownRemark.frontmatter.path
        this.postTitle = this.props.data.markdownRemark.frontmatter.title
        this.postExcerpt = this.props.data.markdownRemark.excerpt
    } 

    render(){
        const { pageContext, data, classes, location } = this.props
        const post = data.markdownRemark
        const fluid = post.frontmatter.thumbnail.childImageSharp.fluid
        const resizedImage = post.frontmatter.thumbnail.childImageSharp.resize

        const disqusConfig = {
            url: siteConfig.siteUrl + post.frontmatter.path,
            identifier: post.id,
            title: post.frontmatter.title,
        }

        return (
            <Layout>
                <section className={classes.blogContainer}>
                    <Helmet 
                        title={post.frontmatter.title}
                        meta={[
                            { name: 'description', content: this.postExcerpt },
                            { property: 'og:type', content: 'article' },
                            { property: 'og:title', content: post.frontmatter.title },
                            { property: 'og:image', content: siteConfig.siteUrl + resizedImage.src },
                            { property: 'og:image:type', content: 'image/jpeg' },
                            { property: 'og:image:width', content: '200' },
                            { property: 'og:image:height', content: '200' },
                            { property: 'og:image:alt', content: post.frontmatter.title },
                            { property: 'og:url', content: siteConfig.siteUrl + post.frontmatter.path },
                            { property: 'og:description', content: this.postExcerpt },
                        ]}
                        // link={[
                        //     { rel: 'canonical', href: `${location.href}` }
                        // ]}
                    />
                    <article className={classes.blogPost}>
                        <Typography className={classes.blogTitle} variant='h1'>
                            {post.frontmatter.title}
                        </Typography>
                        <Typography component='span' >
                            <PostCategories cats={post.frontmatter.categories} row={true} hasIn={true} />
                        </Typography>
                        <div className={classes.blogDateTime}>
                            <Typography className={classes.blogDate} component='span'>
                                <small>{post.frontmatter.date}</small>
                            </Typography>
                            <Typography className={classes.blogTime} component='span'>
                                <MinutesToRead minutes={post.timeToRead} />
                            </Typography>
                        </div>
                        <Img
                            title={`${post.frontmatter.title} thumbnail`}
                            alt={`${post.frontmatter.title} thumbnail`}
                            fluid={fluid} 
                            className={classes.postThumbnail} 
                        />
                        <Typography
                            className={classes.blogContent}
                            dangerouslySetInnerHTML={{ __html: post.html }}
                            variant='body1'
                            align='left'
                            component='div'
                        />
                        <PostTags tags={post.frontmatter.tags} row={true} />

                        <PostsNav context={pageContext} />
                        {/* <hr className={classes.postHr} />
                        <Typography component='div' className={classes.postFooter}>
                            * You are free to report every mistake you find. Just let me know and I will correct it. * <br />
                            * If you've liked this article and found it useful, you can share it with your friends. Maybe they will find it helpful too. *
                        </Typography>
                        <hr className={classes.postHr} /> */}
                        <div className={classes.disqusForm}>
                            <CommentCount config={disqusConfig} placeholder={'...'} />
                            <Disqus config={disqusConfig} />
                        </div>
                    </article>
                </section>
                <SocialPortal>
                    <SocialShare url={this.postURL} title={this.postTitle} excerpt={this.postExcerpt} />
                </SocialPortal>
            </Layout>
        )
    }
}

Template.propTypes = {
    data: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
}

export default withStyles(styles)(Template)

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      id
      excerpt
      html
      timeToRead
      frontmatter {
        date(formatString: "MMM Do YYYY")
        path
        title
        tags
        categories
        thumbnail {
            childImageSharp {
              fluid(maxWidth: 800, maxHeight: 600,
                duotone: {
                  highlight: "#A20471",
                  shadow: "#40C4FF",
                  opacity: 20
                }, quality: 100, cropFocus: CENTER) {
                  base64
                  src
                  srcSet
                  sizes
                  aspectRatio
                  originalImg
              }
            }
            childImageSharp {
                resize(width: 200, height: 200,
                  duotone: {
                    highlight: "#A20471",
                    shadow: "#40C4FF",
                    opacity: 20
                  }, quality: 80, cropFocus: CENTER) {
                    src
                }
              }
          }
      }
    }
  }
`